<template>
  <div class="mcia-outer-conatiner">
    <div class="mcia-inner-container">
      <mds-layout-grid class="product-details-section">
        <mds-row v-if="flipped">
          <mds-col class="product-details-section-row-flippedimg">
            <img :src="imagePath" />
          </mds-col>
          <mds-col class="product-details-section-row-description">
            <div class="item-intro" v-html="introduction"></div>
            <br />
            <div
              v-for="(item, index) in keyPoints"
              :key="`key-points-${index}`"
            >
              <ul>
                <li>{{ item }}</li>
              </ul>
            </div>
            <mds-button
              class="product-key-benefits-action-btn"
              variation="primary"
              size="large"
              @click="toggle = !toggle"
            >
              Request a Demo
            </mds-button>
          </mds-col>
        </mds-row>
        <mds-row v-else>
          <mds-col class="product-details-section-row-description">
            <div class="item-intro" v-html="introduction"></div>
            <br />
            <div
              v-for="(item, index) in keyPoints"
              class="display-content"
              :key="`key-points-${index}`"
            >
              <ul>
                <li>{{ item }}</li>
              </ul>
            </div>
            <mds-button
              class="product-key-benefits-action-btn"
              variation="primary"
              size="large"
              @click="toggle = !toggle"
            >
              Request a Demo
            </mds-button>
          </mds-col>
          <mds-col class="product-details-section-row-img">
            <img :src="imagePath" />
          </mds-col>
        </mds-row>
        <mds-modal
          v-model="toggle"
          class="mcia-get-started-form-model"
          aria-label="Default Modal"
          size="medium"
          :width="'900px'"
        >
          <get-started-form
            form-id="get-started-form-model"
            @exit="getStartedFormToggled"
            @toggleNotification="getStartedToggleNotification"
          />
        </mds-modal>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
import MdsModal from '@mds/modal';
import GetStartedForm from '@/components/GetStartedForm/GetStartedForm.vue';

export default {
  name: 'PlatformItem',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsModal,
    GetStartedForm,
  },
  data() {
    return {
      toggle: false,
      showNotification: false,
    };
  },
  computed: {
    imagePath() {
      return require('../../../assets/' + this.imageFileName);
    }
  },
  methods: {
    getStartedFormToggled() {
      this.toggle = !this.toggle;
    },
    getStartedToggleNotification(toggle) {
      this.showNotification = toggle;
      if (this.showNotification) {
        setTimeout(() => {
          this.showNotification = false;
        }, 1000);
      }
    },
  },
  props: {
    introduction: {
      type: String,
      default: '',
      required: true,
    },
    keyPoints: {
      type: Array,
      required: true,
    },
    imageFileName: {
      type: String,
      default: '',
      required: true,
    },
    flipped: {
      type: Boolean,
      default: false,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.product-details-section {
  height: auto;
  width: 100%;
  margin-top: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
  margin-bottom: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
  &-row {
    &-img {
      display: flex;
      justify-content: right;
      width: 430px;
      height: 357px;
    }
    &-flippedimg {
      display: flex;
      justify-content: left;
      width: 430px;
      height: 357px;
    }
  }
}
.mcia-outer-conatiner {
  .mcia-inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.item-intro{
  @include mds-level-2-heading($bold: true);
  font-size: 28px;
  // width: 90%;
}
ul {
  @include mds-unordered-list(large);
}
ul li {
  &::before{
    color: $mds-text-color-primary;
  }
  line-height: 31px;
}
.product-key-benefits-action-btn {
  margin-top: 35px;
}
.display-content {
  display: flex;
}
img {
  max-width: -webkit-fill-available;
  @media (max-width: $mds-breakpoint-m) {
    padding-top: $mds-space-1-and-a-half-x;
  }
}
</style>
